import { locales } from './localesConfig.json'
import type { CustomNuxtI18nOptions } from './nuxti18n.types'

export const vueI18n = {
  silentFallbackWarn: false,
  fallbackLocale: 'en-US',
  numberFormats: {
    'en-US': { currency: { style: 'currency', currency: 'USD', currencyDisplay: 'symbol' } },
    'en-CA': { currency: { style: 'currency', currency: 'CAD', currencyDisplay: 'symbol' } },
    'en-GB': { currency: { style: 'currency', currency: 'GBP', currencyDisplay: 'symbol' } }
  }
}

const LOCALE_COOKIE_KEY = 'agsf-locale'
const differentDomains = locales.length > 1 && locales.at(0)?.domain !== locales.at(1)?.domain

const i18n: CustomNuxtI18nOptions = {
  differentDomains,
  types: 'composition',
  skipSettingLocaleOnNavigate: false,
  detectBrowserLanguage: {
    useCookie: true,
    cookieKey: LOCALE_COOKIE_KEY
  },
  locales,
  vueI18n: './vue-i18n.options.ts',
  strategy: 'prefix_except_default',
  defaultLocale: 'us',
  langDir: 'lang/',
  lazy: true
}

export default i18n
