import { defineRule } from 'vee-validate'
const rEmail =
  // eslint-disable-next-line
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export const validationConfig = (ctx: any) => {
  const i18n = ctx.$i18n

  // install rules
  defineRule('email', (value: any) => {
    if (typeof value === 'string' && value && rEmail.test(value)) {
      return true
    }
    return String(i18n.t('forms.errors.email.valid'))
  })
  defineRule('min', (value: any, { length }, { field }) => {
    if (!value) {
      return true
    }
    const saveValue = String(value || '')
    if (saveValue.length >= length) {
      return true
    }

    return String(i18n.t('forms.errors.minlength', { name: field, minlength: length }))
  })
  defineRule('length', (value: any, { length }, { field }) => {
    const safeValue = String(value || '')
    if (safeValue.length === length) {
      return true
    }
    return String(i18n.t('forms.errors.length', { name: field, length }))
  })
  defineRule('max', (value: any, { length }, { field }) => {
    const safeValue = String(value || '')
    if (safeValue.length < length) {
      return true
    }

    return String(i18n.t('forms.errors.maxlength', { name: field, maxlength: length }))
  })

  defineRule('numbersAmount', (value: any, opts, { field }) => {
    const min = Array.isArray(opts) ? opts[0] : opts.min

    const safeValue = String(value || '')
    let count = 0

    for (let i = 0; i < safeValue.length; i++) {
      const char = safeValue.charAt(i)

      if (/\d/.test(char)) {
        count++
      }
    }
    if (count >= min) {
      return true
    }

    return String(i18n.t('forms.errors.password.number', { name: field, min }))
  })

  defineRule('lowerCaseAmount', (value: any, opts, { field }) => {
    const min = Array.isArray(opts) ? opts[0] : opts.min

    const safeValue = String(value || '')
    let count = 0

    for (let i = 0; i < safeValue.length; i++) {
      const char = safeValue.charAt(i)

      if (char === char.toLowerCase()) {
        count++
      }
    }
    if (count >= min) {
      return true
    }

    return String(i18n.t('forms.errors.password.lowerCase', { name: field, min }))
  })

  defineRule('upperCaseAmount', (value: any, opts, { field }) => {
    const min = Array.isArray(opts) ? opts[0] : opts.min
    const safeValue = String(value || '')
    let count = 0

    for (let i = 0; i < safeValue.length; i++) {
      const char = safeValue.charAt(i)

      if (char === char.toUpperCase()) {
        count++
      }
    }
    if (count >= min) {
      return true
    }

    return String(i18n.t('forms.errors.password.upperCase', { name: field, min }))
  })
  const spetialChars = `"($%/[]=?!.,-_*|+~#).'`
  defineRule('specialCharsAmount', (value: any, opts, { field }) => {
    const min = Array.isArray(opts) ? opts[0] : opts.min

    const safeValue = String(value || '')
    let count = 0

    for (let i = 0; i < safeValue.length; i++) {
      const char = safeValue.charAt(i)

      if (spetialChars.includes(char)) {
        count++
      }
    }
    if (count >= min) {
      return true
    }

    return String(i18n.t('forms.errors.password.special', { name: field, min }))
  })

  defineRule('required', (value: any) => {
    if (value) {
      return true
    }
    return String(i18n.t('forms.errors.required.field'))
  })
  // @ts-expect-error
  defineRule('regex', (value, [regexStr], field) => {
    if (!value) {
      return true
    }

    const regex = new RegExp(regexStr)

    if (regex.test(value as string)) {
      return true
    }

    return String(i18n.t('forms.errors.parse', { name: field.label || field.name }))
  })
  // @ts-expect-error
  defineRule('password-confirm', (value, [target]) => {
    if (value === target) {
      return true
    }
    return String(i18n.t('forms.errors.password.confirm'))
  })
  // @ts-expect-error
  defineRule('email-confirm', (value, [target]) => {
    if (value === target) {
      return true
    }
    return String(i18n.t('forms.errors.email.confirm'))
  })
}
