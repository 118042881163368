<script setup lang="ts">
import type { AsfWrapperProps } from '@ui/types'

const props = withDefaults(defineProps<AsfWrapperProps>(), {
  tag: 'div',
  type: 'section'
})
const modifier = computed(() => `m-${props.type}`)
</script>
<template>
  <component :is="tag || 'div'" class="asf-wrapper" :class="[modifier]">
    <!-- @slot content -->
    <slot />
  </component>
</template>
<style lang="postcss">
@import '@components/atoms/Wrapper/Wrapper.css';
</style>
