<template>
  <div class="asf-error__content">
    <p class="asf-error__content-top">
      {{ $t('errorPages.serviceBlock.callUs') }}
      <AsfLink class="asf-link-primary" :link="`tel:${$t('global.phone')}`">
        {{ $t('global.phone') }}
      </AsfLink>
      <br />
      {{
        `
        ${$t('errorPages.serviceBlock.weAreHere')}
        ${$t('errorPages.serviceBlock.workingHoursWeekdays')},
        ${$t('errorPages.serviceBlock.workingHoursSaturday')}`
      }}
    </p>
    <p>{{ $t('errorPages.serviceBlock.emailUs') }}</p>
    <AsfLink class="asf-link-primary" :link="`mailto:${$t('global.email')}`">
      {{ $t('global.email') }}
    </AsfLink>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  name: 'AsfErrorServiceBlock'
})
</script>
