import type { DirectiveBinding } from '#app/compat/capi'

export default defineNuxtPlugin((nuxtApp) => {
  const setScrapAttr = (el: HTMLElement, binding: DirectiveBinding<any>) => {
    const enabled = nuxtApp.$config.public.NUXT_ENV_E2E === 'true'
    return enabled && el.setAttribute('data-scrap-id', binding.value)
  }
  const setTestAttr = (el: HTMLElement, binding: DirectiveBinding<any>) => {
    const enabled = nuxtApp.$config.public.NUXT_ENV_E2E === 'true'
    return enabled && el.setAttribute('data-test-id', binding.value)
  }
  nuxtApp.vueApp.directive('e2e', { updated: setTestAttr, mounted: setTestAttr })
  // scrap directive is used for scrap data from site
  nuxtApp.vueApp.directive('scrap', { mounted: setScrapAttr })
  // runs on client side to give a signal to tests that page is loaded
  if (process.client && nuxtApp.$config.public.NUXT_ENV_E2E === 'true') {
    nuxtApp.hook('page:finish', () => {
      const event = new CustomEvent('page:finish')
      window.dispatchEvent(event)
    })
  }
})
