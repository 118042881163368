
// @ts-nocheck


export const localeCodes =  [
  "us",
  "ca",
  "gb"
]

export const localeLoaders = {
  "us": [{ key: "../lang/en-US-default.mjs", load: () => import("../lang/en-US-default.mjs" /* webpackChunkName: "locale__var_www_packages_brandApp_lang_en_US_default_mjs" */), cache: true }],
  "ca": [{ key: "../lang/en-CA.mjs", load: () => import("../lang/en-CA.mjs" /* webpackChunkName: "locale__var_www_packages_brandApp_lang_en_CA_mjs" */), cache: true }],
  "gb": [{ key: "../lang/en-GB.mjs", load: () => import("../lang/en-GB.mjs" /* webpackChunkName: "locale__var_www_packages_brandApp_lang_en_GB_mjs" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../vue-i18n.options.ts?hash=0a677049&config=1" /* webpackChunkName: "__vue_i18n_options_ts_0a677049" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./vue-i18n.options.ts",
  "locales": [
    {
      "code": "us",
      "domain": "http://localhost:3000",
      "iso": "en-US",
      "currency": "USD",
      "int": {
        "scl": {
          "shopId": 1001
        }
      },
      "files": [
        "lang/en-US-default.mjs"
      ]
    },
    {
      "code": "ca",
      "domain": "http://localhost:3000",
      "iso": "en-CA",
      "currency": "CAD",
      "int": {
        "scl": {
          "shopId": 1001
        }
      },
      "files": [
        "lang/en-CA.mjs"
      ]
    },
    {
      "code": "gb",
      "domain": "http://localhost:3000",
      "iso": "en-GB",
      "currency": "GBP",
      "int": {
        "scl": {
          "shopId": 1001
        }
      },
      "files": [
        "lang/en-GB.mjs"
      ]
    }
  ],
  "defaultLocale": "us",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "lang/",
  "detectBrowserLanguage": {
    "alwaysRedirect": false,
    "cookieCrossOrigin": false,
    "cookieDomain": null,
    "cookieKey": "agsf-locale",
    "cookieSecure": false,
    "fallbackLocale": "",
    "redirectOn": "root",
    "useCookie": true
  },
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "us",
    "domain": "http://localhost:3000",
    "iso": "en-US",
    "currency": "USD",
    "int": {
      "scl": {
        "shopId": 1001
      }
    },
    "files": [
      {
        "path": "lang/en-US-default.mjs"
      }
    ]
  },
  {
    "code": "ca",
    "domain": "http://localhost:3000",
    "iso": "en-CA",
    "currency": "CAD",
    "int": {
      "scl": {
        "shopId": 1001
      }
    },
    "files": [
      {
        "path": "lang/en-CA.mjs"
      }
    ]
  },
  {
    "code": "gb",
    "domain": "http://localhost:3000",
    "iso": "en-GB",
    "currency": "GBP",
    "int": {
      "scl": {
        "shopId": 1001
      }
    },
    "files": [
      {
        "path": "lang/en-GB.mjs"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
