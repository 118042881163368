import { default as _91_46_46_46contentSlug_93Mhb1UVvzcnMeta } from "/var/www/packages/int-amplience/pages/[...contentSlug].vue?macro=true";
import { default as add_45addressag1BJdizIMMeta } from "/var/www/packages/base/pages/account/add-address.vue?macro=true";
import { default as add_45paymentQokntha98pMeta } from "/var/www/packages/base/pages/account/add-payment.vue?macro=true";
import { default as address_45bookIy84M4epsjMeta } from "/var/www/packages/base/pages/account/address-book.vue?macro=true";
import { default as change_45password81I4NTtqAAMeta } from "/var/www/packages/base/pages/account/change-password.vue?macro=true";
import { default as edit_45addressWzodmGtho2Meta } from "/var/www/packages/base/pages/account/edit-address.vue?macro=true";
import { default as orderdetailsDMKCsbvcj3Meta } from "/var/www/packages/base/pages/account/orderdetails.vue?macro=true";
import { default as ordersItNoLhVbOEMeta } from "/var/www/packages/base/pages/account/orders.vue?macro=true";
import { default as payment_45methodsO6L9XkZBXgMeta } from "/var/www/packages/base/pages/account/payment-methods.vue?macro=true";
import { default as profile_45detailsbncmaQhHBRMeta } from "/var/www/packages/base/pages/account/profile-details.vue?macro=true";
import { default as wishlistyUf4vSNkl6Meta } from "/var/www/packages/base/pages/account/wishlist.vue?macro=true";
import { default as accountE5LVu7EyUbMeta } from "/var/www/packages/base/pages/account.vue?macro=true";
import { default as _91_46_46_46slug_93tadbCaNb5wMeta } from "/var/www/packages/base/pages/c/[...slug].vue?macro=true";
import { default as cart7fnh4XjS5oMeta } from "/var/www/packages/base/pages/cart.vue?macro=true";
import { default as billingpcdvyBVcHNMeta } from "/var/www/packages/base/pages/checkout/billing.vue?macro=true";
import { default as loginMwVVfcCbY7Meta } from "/var/www/packages/base/pages/checkout/login.vue?macro=true";
import { default as reviewTMRihbCm0PMeta } from "/var/www/packages/base/pages/checkout/review.vue?macro=true";
import { default as shipping0dh18B6jTnMeta } from "/var/www/packages/base/pages/checkout/shipping.vue?macro=true";
import { default as thank_45youIc31kGorbSMeta } from "/var/www/packages/base/pages/checkout/thank-you.vue?macro=true";
import { default as checkoute6lZGcly7UMeta } from "/var/www/packages/base/pages/checkout.vue?macro=true";
import { default as indexDWIZLv9IuqMeta } from "/var/www/packages/base/pages/index.vue?macro=true";
import { default as loginehhkEQGJixMeta } from "/var/www/packages/base/pages/login.vue?macro=true";
import { default as logoutiKl8FXhex2Meta } from "/var/www/packages/base/pages/logout.vue?macro=true";
import { default as order_45trackformREsU7L62QhMeta } from "/var/www/packages/base/pages/order-trackform.vue?macro=true";
import { default as _91_46_46_46slug_93Nwz2ijA2qNMeta } from "/var/www/packages/base/pages/p/[...slug].vue?macro=true";
import { default as password_45recoveryNDwCNHjxl8Meta } from "/var/www/packages/base/pages/password-recovery.vue?macro=true";
import { default as wishlistJ7F9m0K8EcMeta } from "/var/www/packages/base/pages/wishlist.vue?macro=true";
export default [
  {
    name: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.name ?? "contentSlug___us",
    path: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.path ?? "/:contentSlug(.*)*",
    meta: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta || {},
    alias: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.alias || [],
    redirect: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.redirect,
    component: () => import("/var/www/packages/int-amplience/pages/[...contentSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.name ?? "contentSlug___ca",
    path: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.path ?? "/ca/:contentSlug(.*)*",
    meta: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta || {},
    alias: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.alias || [],
    redirect: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.redirect,
    component: () => import("/var/www/packages/int-amplience/pages/[...contentSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.name ?? "contentSlug___gb",
    path: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.path ?? "/gb/:contentSlug(.*)*",
    meta: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta || {},
    alias: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.alias || [],
    redirect: _91_46_46_46contentSlug_93Mhb1UVvzcnMeta?.redirect,
    component: () => import("/var/www/packages/int-amplience/pages/[...contentSlug].vue").then(m => m.default || m)
  },
  {
    name: accountE5LVu7EyUbMeta?.name ?? "account___us",
    path: accountE5LVu7EyUbMeta?.path ?? "/account",
    meta: accountE5LVu7EyUbMeta || {},
    alias: accountE5LVu7EyUbMeta?.alias || [],
    redirect: accountE5LVu7EyUbMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: add_45addressag1BJdizIMMeta?.name ?? "account-add-address___us",
    path: add_45addressag1BJdizIMMeta?.path ?? "add-address",
    meta: add_45addressag1BJdizIMMeta || {},
    alias: add_45addressag1BJdizIMMeta?.alias || [],
    redirect: add_45addressag1BJdizIMMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/add-address.vue").then(m => m.default || m)
  },
  {
    name: add_45paymentQokntha98pMeta?.name ?? "account-add-payment___us",
    path: add_45paymentQokntha98pMeta?.path ?? "add-payment",
    meta: add_45paymentQokntha98pMeta || {},
    alias: add_45paymentQokntha98pMeta?.alias || [],
    redirect: add_45paymentQokntha98pMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/add-payment.vue").then(m => m.default || m)
  },
  {
    name: address_45bookIy84M4epsjMeta?.name ?? "account-address-book___us",
    path: address_45bookIy84M4epsjMeta?.path ?? "address-book",
    meta: address_45bookIy84M4epsjMeta || {},
    alias: address_45bookIy84M4epsjMeta?.alias || [],
    redirect: address_45bookIy84M4epsjMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/address-book.vue").then(m => m.default || m)
  },
  {
    name: change_45password81I4NTtqAAMeta?.name ?? "account-change-password___us",
    path: change_45password81I4NTtqAAMeta?.path ?? "change-password",
    meta: change_45password81I4NTtqAAMeta || {},
    alias: change_45password81I4NTtqAAMeta?.alias || [],
    redirect: change_45password81I4NTtqAAMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: edit_45addressWzodmGtho2Meta?.name ?? "account-edit-address___us",
    path: edit_45addressWzodmGtho2Meta?.path ?? "edit-address",
    meta: edit_45addressWzodmGtho2Meta || {},
    alias: edit_45addressWzodmGtho2Meta?.alias || [],
    redirect: edit_45addressWzodmGtho2Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/edit-address.vue").then(m => m.default || m)
  },
  {
    name: orderdetailsDMKCsbvcj3Meta?.name ?? "account-orderdetails___us",
    path: orderdetailsDMKCsbvcj3Meta?.path ?? "orderdetails",
    meta: orderdetailsDMKCsbvcj3Meta || {},
    alias: orderdetailsDMKCsbvcj3Meta?.alias || [],
    redirect: orderdetailsDMKCsbvcj3Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/orderdetails.vue").then(m => m.default || m)
  },
  {
    name: ordersItNoLhVbOEMeta?.name ?? "account-orders___us",
    path: ordersItNoLhVbOEMeta?.path ?? "orders",
    meta: ordersItNoLhVbOEMeta || {},
    alias: ordersItNoLhVbOEMeta?.alias || [],
    redirect: ordersItNoLhVbOEMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodsO6L9XkZBXgMeta?.name ?? "account-payment-methods___us",
    path: payment_45methodsO6L9XkZBXgMeta?.path ?? "payment-methods",
    meta: payment_45methodsO6L9XkZBXgMeta || {},
    alias: payment_45methodsO6L9XkZBXgMeta?.alias || [],
    redirect: payment_45methodsO6L9XkZBXgMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: profile_45detailsbncmaQhHBRMeta?.name ?? "account-profile-details___us",
    path: profile_45detailsbncmaQhHBRMeta?.path ?? "profile-details",
    meta: profile_45detailsbncmaQhHBRMeta || {},
    alias: profile_45detailsbncmaQhHBRMeta?.alias || [],
    redirect: profile_45detailsbncmaQhHBRMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/profile-details.vue").then(m => m.default || m)
  },
  {
    name: wishlistyUf4vSNkl6Meta?.name ?? "account-wishlist___us",
    path: wishlistyUf4vSNkl6Meta?.path ?? "wishlist",
    meta: wishlistyUf4vSNkl6Meta || {},
    alias: wishlistyUf4vSNkl6Meta?.alias || [],
    redirect: wishlistyUf4vSNkl6Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountE5LVu7EyUbMeta?.name ?? "account___ca",
    path: accountE5LVu7EyUbMeta?.path ?? "/ca/account",
    meta: accountE5LVu7EyUbMeta || {},
    alias: accountE5LVu7EyUbMeta?.alias || [],
    redirect: accountE5LVu7EyUbMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: add_45addressag1BJdizIMMeta?.name ?? "account-add-address___ca",
    path: add_45addressag1BJdizIMMeta?.path ?? "add-address",
    meta: add_45addressag1BJdizIMMeta || {},
    alias: add_45addressag1BJdizIMMeta?.alias || [],
    redirect: add_45addressag1BJdizIMMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/add-address.vue").then(m => m.default || m)
  },
  {
    name: add_45paymentQokntha98pMeta?.name ?? "account-add-payment___ca",
    path: add_45paymentQokntha98pMeta?.path ?? "add-payment",
    meta: add_45paymentQokntha98pMeta || {},
    alias: add_45paymentQokntha98pMeta?.alias || [],
    redirect: add_45paymentQokntha98pMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/add-payment.vue").then(m => m.default || m)
  },
  {
    name: address_45bookIy84M4epsjMeta?.name ?? "account-address-book___ca",
    path: address_45bookIy84M4epsjMeta?.path ?? "address-book",
    meta: address_45bookIy84M4epsjMeta || {},
    alias: address_45bookIy84M4epsjMeta?.alias || [],
    redirect: address_45bookIy84M4epsjMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/address-book.vue").then(m => m.default || m)
  },
  {
    name: change_45password81I4NTtqAAMeta?.name ?? "account-change-password___ca",
    path: change_45password81I4NTtqAAMeta?.path ?? "change-password",
    meta: change_45password81I4NTtqAAMeta || {},
    alias: change_45password81I4NTtqAAMeta?.alias || [],
    redirect: change_45password81I4NTtqAAMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: edit_45addressWzodmGtho2Meta?.name ?? "account-edit-address___ca",
    path: edit_45addressWzodmGtho2Meta?.path ?? "edit-address",
    meta: edit_45addressWzodmGtho2Meta || {},
    alias: edit_45addressWzodmGtho2Meta?.alias || [],
    redirect: edit_45addressWzodmGtho2Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/edit-address.vue").then(m => m.default || m)
  },
  {
    name: orderdetailsDMKCsbvcj3Meta?.name ?? "account-orderdetails___ca",
    path: orderdetailsDMKCsbvcj3Meta?.path ?? "orderdetails",
    meta: orderdetailsDMKCsbvcj3Meta || {},
    alias: orderdetailsDMKCsbvcj3Meta?.alias || [],
    redirect: orderdetailsDMKCsbvcj3Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/orderdetails.vue").then(m => m.default || m)
  },
  {
    name: ordersItNoLhVbOEMeta?.name ?? "account-orders___ca",
    path: ordersItNoLhVbOEMeta?.path ?? "orders",
    meta: ordersItNoLhVbOEMeta || {},
    alias: ordersItNoLhVbOEMeta?.alias || [],
    redirect: ordersItNoLhVbOEMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodsO6L9XkZBXgMeta?.name ?? "account-payment-methods___ca",
    path: payment_45methodsO6L9XkZBXgMeta?.path ?? "payment-methods",
    meta: payment_45methodsO6L9XkZBXgMeta || {},
    alias: payment_45methodsO6L9XkZBXgMeta?.alias || [],
    redirect: payment_45methodsO6L9XkZBXgMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: profile_45detailsbncmaQhHBRMeta?.name ?? "account-profile-details___ca",
    path: profile_45detailsbncmaQhHBRMeta?.path ?? "profile-details",
    meta: profile_45detailsbncmaQhHBRMeta || {},
    alias: profile_45detailsbncmaQhHBRMeta?.alias || [],
    redirect: profile_45detailsbncmaQhHBRMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/profile-details.vue").then(m => m.default || m)
  },
  {
    name: wishlistyUf4vSNkl6Meta?.name ?? "account-wishlist___ca",
    path: wishlistyUf4vSNkl6Meta?.path ?? "wishlist",
    meta: wishlistyUf4vSNkl6Meta || {},
    alias: wishlistyUf4vSNkl6Meta?.alias || [],
    redirect: wishlistyUf4vSNkl6Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: accountE5LVu7EyUbMeta?.name ?? "account___gb",
    path: accountE5LVu7EyUbMeta?.path ?? "/gb/account",
    meta: accountE5LVu7EyUbMeta || {},
    alias: accountE5LVu7EyUbMeta?.alias || [],
    redirect: accountE5LVu7EyUbMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account.vue").then(m => m.default || m),
    children: [
  {
    name: add_45addressag1BJdizIMMeta?.name ?? "account-add-address___gb",
    path: add_45addressag1BJdizIMMeta?.path ?? "add-address",
    meta: add_45addressag1BJdizIMMeta || {},
    alias: add_45addressag1BJdizIMMeta?.alias || [],
    redirect: add_45addressag1BJdizIMMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/add-address.vue").then(m => m.default || m)
  },
  {
    name: add_45paymentQokntha98pMeta?.name ?? "account-add-payment___gb",
    path: add_45paymentQokntha98pMeta?.path ?? "add-payment",
    meta: add_45paymentQokntha98pMeta || {},
    alias: add_45paymentQokntha98pMeta?.alias || [],
    redirect: add_45paymentQokntha98pMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/add-payment.vue").then(m => m.default || m)
  },
  {
    name: address_45bookIy84M4epsjMeta?.name ?? "account-address-book___gb",
    path: address_45bookIy84M4epsjMeta?.path ?? "address-book",
    meta: address_45bookIy84M4epsjMeta || {},
    alias: address_45bookIy84M4epsjMeta?.alias || [],
    redirect: address_45bookIy84M4epsjMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/address-book.vue").then(m => m.default || m)
  },
  {
    name: change_45password81I4NTtqAAMeta?.name ?? "account-change-password___gb",
    path: change_45password81I4NTtqAAMeta?.path ?? "change-password",
    meta: change_45password81I4NTtqAAMeta || {},
    alias: change_45password81I4NTtqAAMeta?.alias || [],
    redirect: change_45password81I4NTtqAAMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/change-password.vue").then(m => m.default || m)
  },
  {
    name: edit_45addressWzodmGtho2Meta?.name ?? "account-edit-address___gb",
    path: edit_45addressWzodmGtho2Meta?.path ?? "edit-address",
    meta: edit_45addressWzodmGtho2Meta || {},
    alias: edit_45addressWzodmGtho2Meta?.alias || [],
    redirect: edit_45addressWzodmGtho2Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/edit-address.vue").then(m => m.default || m)
  },
  {
    name: orderdetailsDMKCsbvcj3Meta?.name ?? "account-orderdetails___gb",
    path: orderdetailsDMKCsbvcj3Meta?.path ?? "orderdetails",
    meta: orderdetailsDMKCsbvcj3Meta || {},
    alias: orderdetailsDMKCsbvcj3Meta?.alias || [],
    redirect: orderdetailsDMKCsbvcj3Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/orderdetails.vue").then(m => m.default || m)
  },
  {
    name: ordersItNoLhVbOEMeta?.name ?? "account-orders___gb",
    path: ordersItNoLhVbOEMeta?.path ?? "orders",
    meta: ordersItNoLhVbOEMeta || {},
    alias: ordersItNoLhVbOEMeta?.alias || [],
    redirect: ordersItNoLhVbOEMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/orders.vue").then(m => m.default || m)
  },
  {
    name: payment_45methodsO6L9XkZBXgMeta?.name ?? "account-payment-methods___gb",
    path: payment_45methodsO6L9XkZBXgMeta?.path ?? "payment-methods",
    meta: payment_45methodsO6L9XkZBXgMeta || {},
    alias: payment_45methodsO6L9XkZBXgMeta?.alias || [],
    redirect: payment_45methodsO6L9XkZBXgMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/payment-methods.vue").then(m => m.default || m)
  },
  {
    name: profile_45detailsbncmaQhHBRMeta?.name ?? "account-profile-details___gb",
    path: profile_45detailsbncmaQhHBRMeta?.path ?? "profile-details",
    meta: profile_45detailsbncmaQhHBRMeta || {},
    alias: profile_45detailsbncmaQhHBRMeta?.alias || [],
    redirect: profile_45detailsbncmaQhHBRMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/profile-details.vue").then(m => m.default || m)
  },
  {
    name: wishlistyUf4vSNkl6Meta?.name ?? "account-wishlist___gb",
    path: wishlistyUf4vSNkl6Meta?.path ?? "wishlist",
    meta: wishlistyUf4vSNkl6Meta || {},
    alias: wishlistyUf4vSNkl6Meta?.alias || [],
    redirect: wishlistyUf4vSNkl6Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/account/wishlist.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91_46_46_46slug_93tadbCaNb5wMeta?.name ?? "c-slug___us",
    path: _91_46_46_46slug_93tadbCaNb5wMeta?.path ?? "/c/:slug(.*)*",
    meta: _91_46_46_46slug_93tadbCaNb5wMeta || {},
    alias: _91_46_46_46slug_93tadbCaNb5wMeta?.alias || [],
    redirect: _91_46_46_46slug_93tadbCaNb5wMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tadbCaNb5wMeta?.name ?? "c-slug___ca",
    path: _91_46_46_46slug_93tadbCaNb5wMeta?.path ?? "/ca/c/:slug(.*)*",
    meta: _91_46_46_46slug_93tadbCaNb5wMeta || {},
    alias: _91_46_46_46slug_93tadbCaNb5wMeta?.alias || [],
    redirect: _91_46_46_46slug_93tadbCaNb5wMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tadbCaNb5wMeta?.name ?? "c-slug___gb",
    path: _91_46_46_46slug_93tadbCaNb5wMeta?.path ?? "/gb/c/:slug(.*)*",
    meta: _91_46_46_46slug_93tadbCaNb5wMeta || {},
    alias: _91_46_46_46slug_93tadbCaNb5wMeta?.alias || [],
    redirect: _91_46_46_46slug_93tadbCaNb5wMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: cart7fnh4XjS5oMeta?.name ?? "cart___us",
    path: cart7fnh4XjS5oMeta?.path ?? "/cart",
    meta: cart7fnh4XjS5oMeta || {},
    alias: cart7fnh4XjS5oMeta?.alias || [],
    redirect: cart7fnh4XjS5oMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cart7fnh4XjS5oMeta?.name ?? "cart___ca",
    path: cart7fnh4XjS5oMeta?.path ?? "/ca/cart",
    meta: cart7fnh4XjS5oMeta || {},
    alias: cart7fnh4XjS5oMeta?.alias || [],
    redirect: cart7fnh4XjS5oMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: cart7fnh4XjS5oMeta?.name ?? "cart___gb",
    path: cart7fnh4XjS5oMeta?.path ?? "/gb/cart",
    meta: cart7fnh4XjS5oMeta || {},
    alias: cart7fnh4XjS5oMeta?.alias || [],
    redirect: cart7fnh4XjS5oMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/cart.vue").then(m => m.default || m)
  },
  {
    name: checkoute6lZGcly7UMeta?.name ?? "checkout___us",
    path: checkoute6lZGcly7UMeta?.path ?? "/checkout",
    meta: checkoute6lZGcly7UMeta || {},
    alias: checkoute6lZGcly7UMeta?.alias || [],
    redirect: checkoute6lZGcly7UMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: billingpcdvyBVcHNMeta?.name ?? "checkout-billing___us",
    path: billingpcdvyBVcHNMeta?.path ?? "billing",
    meta: billingpcdvyBVcHNMeta || {},
    alias: billingpcdvyBVcHNMeta?.alias || [],
    redirect: billingpcdvyBVcHNMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/billing.vue").then(m => m.default || m)
  },
  {
    name: loginMwVVfcCbY7Meta?.name ?? "checkout-login___us",
    path: loginMwVVfcCbY7Meta?.path ?? "login",
    meta: loginMwVVfcCbY7Meta || {},
    alias: loginMwVVfcCbY7Meta?.alias || [],
    redirect: loginMwVVfcCbY7Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: reviewTMRihbCm0PMeta?.name ?? "checkout-review___us",
    path: reviewTMRihbCm0PMeta?.path ?? "review",
    meta: reviewTMRihbCm0PMeta || {},
    alias: reviewTMRihbCm0PMeta?.alias || [],
    redirect: reviewTMRihbCm0PMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shipping0dh18B6jTnMeta?.name ?? "checkout-shipping___us",
    path: shipping0dh18B6jTnMeta?.path ?? "shipping",
    meta: shipping0dh18B6jTnMeta || {},
    alias: shipping0dh18B6jTnMeta?.alias || [],
    redirect: shipping0dh18B6jTnMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: thank_45youIc31kGorbSMeta?.name ?? "checkout-thank-you___us",
    path: thank_45youIc31kGorbSMeta?.path ?? "thank-you",
    meta: thank_45youIc31kGorbSMeta || {},
    alias: thank_45youIc31kGorbSMeta?.alias || [],
    redirect: thank_45youIc31kGorbSMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/thank-you.vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkoute6lZGcly7UMeta?.name ?? "checkout___ca",
    path: checkoute6lZGcly7UMeta?.path ?? "/ca/checkout",
    meta: checkoute6lZGcly7UMeta || {},
    alias: checkoute6lZGcly7UMeta?.alias || [],
    redirect: checkoute6lZGcly7UMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: billingpcdvyBVcHNMeta?.name ?? "checkout-billing___ca",
    path: billingpcdvyBVcHNMeta?.path ?? "billing",
    meta: billingpcdvyBVcHNMeta || {},
    alias: billingpcdvyBVcHNMeta?.alias || [],
    redirect: billingpcdvyBVcHNMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/billing.vue").then(m => m.default || m)
  },
  {
    name: loginMwVVfcCbY7Meta?.name ?? "checkout-login___ca",
    path: loginMwVVfcCbY7Meta?.path ?? "login",
    meta: loginMwVVfcCbY7Meta || {},
    alias: loginMwVVfcCbY7Meta?.alias || [],
    redirect: loginMwVVfcCbY7Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: reviewTMRihbCm0PMeta?.name ?? "checkout-review___ca",
    path: reviewTMRihbCm0PMeta?.path ?? "review",
    meta: reviewTMRihbCm0PMeta || {},
    alias: reviewTMRihbCm0PMeta?.alias || [],
    redirect: reviewTMRihbCm0PMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shipping0dh18B6jTnMeta?.name ?? "checkout-shipping___ca",
    path: shipping0dh18B6jTnMeta?.path ?? "shipping",
    meta: shipping0dh18B6jTnMeta || {},
    alias: shipping0dh18B6jTnMeta?.alias || [],
    redirect: shipping0dh18B6jTnMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: thank_45youIc31kGorbSMeta?.name ?? "checkout-thank-you___ca",
    path: thank_45youIc31kGorbSMeta?.path ?? "thank-you",
    meta: thank_45youIc31kGorbSMeta || {},
    alias: thank_45youIc31kGorbSMeta?.alias || [],
    redirect: thank_45youIc31kGorbSMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/thank-you.vue").then(m => m.default || m)
  }
]
  },
  {
    name: checkoute6lZGcly7UMeta?.name ?? "checkout___gb",
    path: checkoute6lZGcly7UMeta?.path ?? "/gb/checkout",
    meta: checkoute6lZGcly7UMeta || {},
    alias: checkoute6lZGcly7UMeta?.alias || [],
    redirect: checkoute6lZGcly7UMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout.vue").then(m => m.default || m),
    children: [
  {
    name: billingpcdvyBVcHNMeta?.name ?? "checkout-billing___gb",
    path: billingpcdvyBVcHNMeta?.path ?? "billing",
    meta: billingpcdvyBVcHNMeta || {},
    alias: billingpcdvyBVcHNMeta?.alias || [],
    redirect: billingpcdvyBVcHNMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/billing.vue").then(m => m.default || m)
  },
  {
    name: loginMwVVfcCbY7Meta?.name ?? "checkout-login___gb",
    path: loginMwVVfcCbY7Meta?.path ?? "login",
    meta: loginMwVVfcCbY7Meta || {},
    alias: loginMwVVfcCbY7Meta?.alias || [],
    redirect: loginMwVVfcCbY7Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/login.vue").then(m => m.default || m)
  },
  {
    name: reviewTMRihbCm0PMeta?.name ?? "checkout-review___gb",
    path: reviewTMRihbCm0PMeta?.path ?? "review",
    meta: reviewTMRihbCm0PMeta || {},
    alias: reviewTMRihbCm0PMeta?.alias || [],
    redirect: reviewTMRihbCm0PMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/review.vue").then(m => m.default || m)
  },
  {
    name: shipping0dh18B6jTnMeta?.name ?? "checkout-shipping___gb",
    path: shipping0dh18B6jTnMeta?.path ?? "shipping",
    meta: shipping0dh18B6jTnMeta || {},
    alias: shipping0dh18B6jTnMeta?.alias || [],
    redirect: shipping0dh18B6jTnMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/shipping.vue").then(m => m.default || m)
  },
  {
    name: thank_45youIc31kGorbSMeta?.name ?? "checkout-thank-you___gb",
    path: thank_45youIc31kGorbSMeta?.path ?? "thank-you",
    meta: thank_45youIc31kGorbSMeta || {},
    alias: thank_45youIc31kGorbSMeta?.alias || [],
    redirect: thank_45youIc31kGorbSMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/checkout/thank-you.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexDWIZLv9IuqMeta?.name ?? "index___us",
    path: indexDWIZLv9IuqMeta?.path ?? "/",
    meta: indexDWIZLv9IuqMeta || {},
    alias: indexDWIZLv9IuqMeta?.alias || [],
    redirect: indexDWIZLv9IuqMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDWIZLv9IuqMeta?.name ?? "index___ca",
    path: indexDWIZLv9IuqMeta?.path ?? "/ca",
    meta: indexDWIZLv9IuqMeta || {},
    alias: indexDWIZLv9IuqMeta?.alias || [],
    redirect: indexDWIZLv9IuqMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexDWIZLv9IuqMeta?.name ?? "index___gb",
    path: indexDWIZLv9IuqMeta?.path ?? "/gb",
    meta: indexDWIZLv9IuqMeta || {},
    alias: indexDWIZLv9IuqMeta?.alias || [],
    redirect: indexDWIZLv9IuqMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginehhkEQGJixMeta?.name ?? "login___us",
    path: loginehhkEQGJixMeta?.path ?? "/login",
    meta: loginehhkEQGJixMeta || {},
    alias: loginehhkEQGJixMeta?.alias || [],
    redirect: loginehhkEQGJixMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginehhkEQGJixMeta?.name ?? "login___ca",
    path: loginehhkEQGJixMeta?.path ?? "/ca/login",
    meta: loginehhkEQGJixMeta || {},
    alias: loginehhkEQGJixMeta?.alias || [],
    redirect: loginehhkEQGJixMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/login.vue").then(m => m.default || m)
  },
  {
    name: loginehhkEQGJixMeta?.name ?? "login___gb",
    path: loginehhkEQGJixMeta?.path ?? "/gb/login",
    meta: loginehhkEQGJixMeta || {},
    alias: loginehhkEQGJixMeta?.alias || [],
    redirect: loginehhkEQGJixMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logoutiKl8FXhex2Meta?.name ?? "logout___us",
    path: logoutiKl8FXhex2Meta?.path ?? "/logout",
    meta: logoutiKl8FXhex2Meta || {},
    alias: logoutiKl8FXhex2Meta?.alias || [],
    redirect: logoutiKl8FXhex2Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutiKl8FXhex2Meta?.name ?? "logout___ca",
    path: logoutiKl8FXhex2Meta?.path ?? "/ca/logout",
    meta: logoutiKl8FXhex2Meta || {},
    alias: logoutiKl8FXhex2Meta?.alias || [],
    redirect: logoutiKl8FXhex2Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: logoutiKl8FXhex2Meta?.name ?? "logout___gb",
    path: logoutiKl8FXhex2Meta?.path ?? "/gb/logout",
    meta: logoutiKl8FXhex2Meta || {},
    alias: logoutiKl8FXhex2Meta?.alias || [],
    redirect: logoutiKl8FXhex2Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: order_45trackformREsU7L62QhMeta?.name ?? "order-trackform___us",
    path: order_45trackformREsU7L62QhMeta?.path ?? "/order-trackform",
    meta: order_45trackformREsU7L62QhMeta || {},
    alias: order_45trackformREsU7L62QhMeta?.alias || [],
    redirect: order_45trackformREsU7L62QhMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/order-trackform.vue").then(m => m.default || m)
  },
  {
    name: order_45trackformREsU7L62QhMeta?.name ?? "order-trackform___ca",
    path: order_45trackformREsU7L62QhMeta?.path ?? "/ca/order-trackform",
    meta: order_45trackformREsU7L62QhMeta || {},
    alias: order_45trackformREsU7L62QhMeta?.alias || [],
    redirect: order_45trackformREsU7L62QhMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/order-trackform.vue").then(m => m.default || m)
  },
  {
    name: order_45trackformREsU7L62QhMeta?.name ?? "order-trackform___gb",
    path: order_45trackformREsU7L62QhMeta?.path ?? "/gb/order-trackform",
    meta: order_45trackformREsU7L62QhMeta || {},
    alias: order_45trackformREsU7L62QhMeta?.alias || [],
    redirect: order_45trackformREsU7L62QhMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/order-trackform.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Nwz2ijA2qNMeta?.name ?? "p-slug___us",
    path: _91_46_46_46slug_93Nwz2ijA2qNMeta?.path ?? "/p/:slug(.*)*",
    meta: _91_46_46_46slug_93Nwz2ijA2qNMeta || {},
    alias: _91_46_46_46slug_93Nwz2ijA2qNMeta?.alias || [],
    redirect: _91_46_46_46slug_93Nwz2ijA2qNMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Nwz2ijA2qNMeta?.name ?? "p-slug___ca",
    path: _91_46_46_46slug_93Nwz2ijA2qNMeta?.path ?? "/ca/p/:slug(.*)*",
    meta: _91_46_46_46slug_93Nwz2ijA2qNMeta || {},
    alias: _91_46_46_46slug_93Nwz2ijA2qNMeta?.alias || [],
    redirect: _91_46_46_46slug_93Nwz2ijA2qNMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93Nwz2ijA2qNMeta?.name ?? "p-slug___gb",
    path: _91_46_46_46slug_93Nwz2ijA2qNMeta?.path ?? "/gb/p/:slug(.*)*",
    meta: _91_46_46_46slug_93Nwz2ijA2qNMeta || {},
    alias: _91_46_46_46slug_93Nwz2ijA2qNMeta?.alias || [],
    redirect: _91_46_46_46slug_93Nwz2ijA2qNMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/p/[...slug].vue").then(m => m.default || m)
  },
  {
    name: password_45recoveryNDwCNHjxl8Meta?.name ?? "password-recovery___us",
    path: password_45recoveryNDwCNHjxl8Meta?.path ?? "/password-recovery",
    meta: password_45recoveryNDwCNHjxl8Meta || {},
    alias: password_45recoveryNDwCNHjxl8Meta?.alias || [],
    redirect: password_45recoveryNDwCNHjxl8Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/password-recovery.vue").then(m => m.default || m)
  },
  {
    name: password_45recoveryNDwCNHjxl8Meta?.name ?? "password-recovery___ca",
    path: password_45recoveryNDwCNHjxl8Meta?.path ?? "/ca/password-recovery",
    meta: password_45recoveryNDwCNHjxl8Meta || {},
    alias: password_45recoveryNDwCNHjxl8Meta?.alias || [],
    redirect: password_45recoveryNDwCNHjxl8Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/password-recovery.vue").then(m => m.default || m)
  },
  {
    name: password_45recoveryNDwCNHjxl8Meta?.name ?? "password-recovery___gb",
    path: password_45recoveryNDwCNHjxl8Meta?.path ?? "/gb/password-recovery",
    meta: password_45recoveryNDwCNHjxl8Meta || {},
    alias: password_45recoveryNDwCNHjxl8Meta?.alias || [],
    redirect: password_45recoveryNDwCNHjxl8Meta?.redirect,
    component: () => import("/var/www/packages/base/pages/password-recovery.vue").then(m => m.default || m)
  },
  {
    name: wishlistJ7F9m0K8EcMeta?.name ?? "wishlist___us",
    path: wishlistJ7F9m0K8EcMeta?.path ?? "/wishlist",
    meta: wishlistJ7F9m0K8EcMeta || {},
    alias: wishlistJ7F9m0K8EcMeta?.alias || [],
    redirect: wishlistJ7F9m0K8EcMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistJ7F9m0K8EcMeta?.name ?? "wishlist___ca",
    path: wishlistJ7F9m0K8EcMeta?.path ?? "/ca/wishlist",
    meta: wishlistJ7F9m0K8EcMeta || {},
    alias: wishlistJ7F9m0K8EcMeta?.alias || [],
    redirect: wishlistJ7F9m0K8EcMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: wishlistJ7F9m0K8EcMeta?.name ?? "wishlist___gb",
    path: wishlistJ7F9m0K8EcMeta?.path ?? "/gb/wishlist",
    meta: wishlistJ7F9m0K8EcMeta || {},
    alias: wishlistJ7F9m0K8EcMeta?.alias || [],
    redirect: wishlistJ7F9m0K8EcMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/wishlist.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tadbCaNb5wMeta?.name ?? "search___us",
    path: _91_46_46_46slug_93tadbCaNb5wMeta?.path ?? "/search",
    meta: _91_46_46_46slug_93tadbCaNb5wMeta || {},
    alias: _91_46_46_46slug_93tadbCaNb5wMeta?.alias || [],
    redirect: _91_46_46_46slug_93tadbCaNb5wMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tadbCaNb5wMeta?.name ?? "search___ca",
    path: _91_46_46_46slug_93tadbCaNb5wMeta?.path ?? "/ca/search",
    meta: _91_46_46_46slug_93tadbCaNb5wMeta || {},
    alias: _91_46_46_46slug_93tadbCaNb5wMeta?.alias || [],
    redirect: _91_46_46_46slug_93tadbCaNb5wMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/c/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93tadbCaNb5wMeta?.name ?? "search___gb",
    path: _91_46_46_46slug_93tadbCaNb5wMeta?.path ?? "/gb/search",
    meta: _91_46_46_46slug_93tadbCaNb5wMeta || {},
    alias: _91_46_46_46slug_93tadbCaNb5wMeta?.alias || [],
    redirect: _91_46_46_46slug_93tadbCaNb5wMeta?.redirect,
    component: () => import("/var/www/packages/base/pages/c/[...slug].vue").then(m => m.default || m)
  }
]