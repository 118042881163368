import type { IOptions } from 'sanitize-html'
import type { App } from 'vue'
import sanitizeHtml from 'sanitize-html'

const FILTER_INLINE: IOptions = {
  allowedTags: ['a', 'b', 'br', 'code', 'em', 'i', 'span', 'strike', 'strong', 'u'],
  allowedAttributes: {
    a: ['href', 'target'],
    span: ['style']
  },
  selfClosing: ['br'],
  allowedSchemes: ['ftp', 'http', 'https', 'mailto'],
  parser: {
    decodeEntities: true
  }
}
const FILTER_NOTHING: IOptions = {
  allowedTags: false,
  allowedAttributes: false
}
const FILTER_STRIP: IOptions = {
  allowedTags: [],
  allowedAttributes: {}
}

type Sanitize = (dirty: string, opts?: IOptions) => string

// Remove once fixed - https://youtrack.jetbrains.com/issue/WEB-56523
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $sanitize: Sanitize
  }
}

export default defineNuxtPlugin((nuxtApp) => {
  const install = (app: App, options: IOptions = {}) =>
    (app.config.globalProperties.$sanitize = (dirty: string, opts = {}) => {
      return sanitizeHtml(dirty, {
        ...opts,
        ...options
      })
    })

  nuxtApp.vueApp.use(
    { install },
    {
      ...sanitizeHtml.defaults,
      ...(nuxtApp.$config.public.sanitizeHtml || {})
    }
  )

  nuxtApp.vueApp.directive('sanitize', {
    mounted(el, binding) {
      const { $config, $sanitize } = useNuxtApp()
      const sanitizeHtml = $config.public.sanitizeHtml as IOptions

      if (binding.value === binding.oldValue) {
        return
      }

      if (Array.isArray(binding.value)) {
        el.innerHTML = $sanitize(binding.value[1], binding.value[0])
      } else if (binding.modifiers.strip) {
        el.innerHTML = $sanitize(binding.value, FILTER_STRIP)
      } else if (binding.modifiers.basic) {
        el.innerHTML = $sanitize(binding.value, sanitizeHtml)
      } else if (binding.modifiers.inline) {
        el.innerHTML = $sanitize(binding.value, FILTER_INLINE)
      } else if (binding.modifiers.nothing) {
        el.innerHTML = $sanitize(binding.value, FILTER_NOTHING)
      } else {
        el.innerHTML = $sanitize(binding.value, sanitizeHtml)
      }
    }
  })
  const sanitize = nuxtApp.vueApp.config.globalProperties.$sanitize as Sanitize

  return {
    provide: {
      sanitize
    }
  }
})
