import type { AgnosticCategoryLocalized, AgnosticGlobalPagination, AgnosticProductTile } from './index'

export enum AgnosticFacetType {
  singleSelect = 'singleSelect',
  multiSelect = 'multiSelect',
  tree = 'tree',
  swatch = 'swatch',
  toggle = 'toggle',
  range = 'range'
}

export interface AgnosticBaseFacet<V> {
  key: string
  type: AgnosticFacetType
  values: V[]
  label: string
}

export type AgnosticFacetValue = {
  value: string
  label: string
  selected: boolean
  count: number
  from?: number
  to?: number
  // currently specific for color facet
  valueAttr?: string

  checked?: string
  description?: string
  details?: string
  id?: string
  disabled?: boolean
  name?: string
  title?: string
}

export interface AgnosticSearchFacet extends AgnosticBaseFacet<AgnosticFacetValue> {
  label: string
  total: number
}

export type AgnosticSearchPagination = {
  currentPage: number
  totalPages: number
  totalItems: number
  itemsPerPage: number
}

export type AgnosticSearchFacetCategory = {
  key: string
  count: number
  parent?: AgnosticSearchFacetCategory
  children?: readonly AgnosticSearchFacetCategory[]
} & AgnosticCategoryLocalized

export type AgnosticSearchFacetRange = {
  minRangeValue: number
  maxRangeValue: number
  step?: number
  connect?: boolean
  margin?: number
  minStartPosition?: number
  maxStartPosition?: number
  currency?: string
}

export type AgnosticSearchParams = {
  rootCatSlug?: string
  categorySlug?: string
  page?: number
  sort?: string
  filters?: Readonly<Record<string, string[]>>
  itemsPerPage?: number
  q?: string
}

// TODO: create separate for suggestions search
export type AgnosticSearchResult = {
  category?: AgnosticSearchFacetCategory
  categories: readonly AgnosticSearchFacetCategory[]
  products: readonly AgnosticProductTile[]
  pagination: AgnosticGlobalPagination
  breadcrumbs: readonly AgnosticCategoryLocalized[]
  facets: readonly AgnosticSearchFacet[]
  sort?: string
}

export enum AgnosticBaseFacetAttrs {
  color = 'color',
  size = 'size',
  price = 'price',
  category = 'category'
}

export enum AgnosticSortOptions {
  newArrivals = 'new-arrivals',
  priceUp = 'price-up',
  priceDown = 'price-down'
}
