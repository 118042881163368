import type { Directive } from 'vue'

interface ClickOutsideElement extends HTMLElement {
  _outsideClickHandler?: (e: MouseEvent | TouchEvent) => void
}

const doNotHideOn = ['asf-alert']

export const clickOutside: Directive<ClickOutsideElement> = {
  mounted(el, binding) {
    el._outsideClickHandler = (event: MouseEvent | TouchEvent) => {
      const target = event.target as Element
      if (!el.contains(target)) {
        if (Array.from(target.classList).some((elem) => doNotHideOn.includes(elem))) return
        event.stopPropagation()
        binding.value()
      }
    }

    document.addEventListener('mouseup', el._outsideClickHandler)
    document.addEventListener('touchstart', el._outsideClickHandler)
  },
  unmounted(el) {
    if (el._outsideClickHandler) {
      document.removeEventListener('mouseup', el._outsideClickHandler)
      document.removeEventListener('touchstart', el._outsideClickHandler)
    }

    el._outsideClickHandler = undefined
  }
}
