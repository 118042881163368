import type { RouterOptions } from '@nuxt/schema'
import type { RouteLocationNormalized, RouteLocationNormalizedLoaded, RouterScrollBehavior } from 'vue-router'
const isSamePathToAnchor = (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
  if (to.path !== from.path) {
    return null
  }

  if (typeof to.meta.anchor === 'undefined') {
    return null
  }

  return to.meta.anchor === from.meta.anchor
}

const isSamePath = (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
  return to.path === from.path
}

const scrollBehavior: RouterScrollBehavior = (to, from, savedPosition) => {
  const { meta, hash } = to
  const { isMediaMatch: isSmallOrMediumView } = useMediaQuery()

  const stickyHeaderHeight = !isSmallOrMediumView.value ? 80 : 68

  if (savedPosition && !isSamePath(to, from)) {
    return savedPosition
  } else if (hash || isSamePathToAnchor(to, from)) {
    return { el: hash || meta.anchor, left: 0, top: stickyHeaderHeight }
  } else if (isSamePath(to, from)) {
    // Jump to the beginning of the content within the same page
    return { el: '#pageContent', left: 0, top: stickyHeaderHeight }
  } else {
    return { left: 0, top: 0 }
  }
}

export default <RouterOptions>{ scrollBehavior }
