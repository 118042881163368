import type { StorefrontPreferencesForExactLocale } from 'shared/system/settingsMgrHelper'

// Remove once fixed - https://youtrack.jetbrains.com/issue/WEB-56523
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $preferences: StorefrontPreferencesForExactLocale
  }
}

export default defineNuxtPlugin({
  name: 'preferences',
  // or 'post'
  enforce: 'pre',
  async setup() {
    const preferences = useState<any>('pagePreferences')
    const proxy = new Proxy(
      {},
      {
        get: (_, prop) => {
          const locale = useNuxtApp().$i18n.localeProperties.value.iso
          const preference = preferences.value[prop]

          return locale && preference && locale in preference ? preference[locale] : preference?.default
        }
      }
    ) as StorefrontPreferencesForExactLocale

    return {
      provide: {
        preferences: proxy
      }
    }
  }
})
