/* eslint-disable @typescript-eslint/no-extra-semi */
export function tryParse<T>(str: string | null | undefined, defaults: T): T {
  if (!str) {
    return defaults
  }
  try {
    return JSON.parse(str)
  } catch (e) {
    return defaults
  }
}

export function deepEqual(x: any, y: any): boolean {
  return x && y && typeof x === 'object' && typeof y === 'object'
    ? Object.keys(x).length === Object.keys(y).length &&
        Object.keys(x).reduce((isEqual, key) => {
          return isEqual && deepEqual(x[key], y[key])
        }, true)
    : x === y
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

function isObject(value: any): value is object {
  return value && typeof value === 'object'
}

function copy<T>(value: T) {
  return (Array.isArray(value) ? [...value] : isObject(value) ? { ...value } : value) as T
}

export function deepAssign<T extends object, K extends Record<keyof K, any>>(dest: T, src: K) {
  const keysOfSrc = Object.keys(src) as (keyof K)[]
  for (const key of keysOfSrc) {
    const val = src[key]
    if (Array.isArray(val)) {
      ;(dest as any)[key] = [...val.map((item: any) => (isObject(item) ? deepAssign({}, item) : item))]
    } else if (isObject(val)) {
      ;(dest as any)[key] = val
      deepAssign((dest as any)[key], copy(val))
    } else {
      ;(dest as any)[key] = copy(val)
    }
  }

  return dest as T & K
}

export const invertObject = <T extends string | number, K extends string | number>(obj: Record<T, K>) => {
  const keys = Object.keys(obj) as (keyof typeof obj)[]
  return keys.reduce(
    (ret, key) => {
      const value = obj[key]
      ret[value] = key
      return ret
    },
    {} as Record<K, T>
  )
}

export const once = <A extends any[], R, T>(fn: (this: T, ...arg: A) => R): ((this: T, ...arg: A) => R) => {
  let result: R
  return function onceInnter(this: T, ...args: A) {
    if (fn) {
      result = fn.apply(this, args)
      // @ts-ignore
      fn = undefined
    }
    return result
  }
}

export function uniq<T>(arr: T[]): T[] {
  return Array.from(new Set(arr))
}

export function uniqWith<T>(arr: T[], comparator: (a: T, b: T) => boolean): T[] {
  return arr.filter((item, index, self) => {
    return self.findIndex((i) => comparator(i, item)) === index
  })
}
