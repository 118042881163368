import type { Analytics, AnalyticsSubscribeClient } from 'shared/types'

export default defineNuxtPlugin({
  name: 'analytics',
  enforce: 'default',
  async setup() {
    const subscribers: AnalyticsSubscribeClient[] = []

    const analytics: Analytics = {
      subscribe(client) {
        subscribers.push(client)
        return () => {
          const index = subscribers.indexOf(client)
          if (index > -1) {
            subscribers.splice(index, 1)
          }
        }
      },
      unsubscribe(client) {
        const index = subscribers.indexOf(client)
        if (index > -1) {
          subscribers.splice(index, 1)
        }
      },
      publish(event) {
        subscribers.forEach((client) => {
          client(event)
        })
      }
    }

    return {
      provide: {
        analytics
      }
    }
  }
})
