import { validationConfig } from 'shared/config/vee-validate'

export default defineNuxtPlugin({
  name: 'vee-validate',
  // or 'post'
  enforce: 'post',
  async setup(nuxtApp) {
    return validationConfig(nuxtApp)
  }
})
