import type { Directive } from 'vue'
import type { FocusableElement, FocusElement } from './types'

const focusableInputElements = 'input, select, textarea, label[tabindex="0"]'
const focusableElements = 'a[href], button, .simplebar-content-wrapper, ' + focusableInputElements

const isHidden = (selector: FocusableElement) =>
  selector.offsetParent === null ||
  selector.ariaHidden === 'true' ||
  ('disabled' in selector && selector.disabled) ||
  selector.tabIndex === -1

export const getFocusableChildren = (el: HTMLElement, inputElements = false) => {
  const result: FocusableElement[] = Array.from(
    el.querySelectorAll(inputElements ? focusableInputElements : focusableElements)
  )

  return result.filter((selector) => !isHidden(selector))
}

const isFocusable = (e: KeyboardEvent, focusableChildrenElements?: Element[]) =>
  focusableChildrenElements && Array.from(focusableChildrenElements).some((el) => el === e.target)

const moveFocus = (e: KeyboardEvent, focusableChildrenElements?: FocusableElement[]) => {
  if (!focusableChildrenElements || !focusableChildrenElements.length || e.key !== 'Tab') return

  if (!isFocusable(e, focusableChildrenElements)) {
    e.preventDefault()
    focusableChildrenElements[0]?.focus()
  }

  if (focusableChildrenElements.length === 1) {
    e.preventDefault()
    return
  }

  const lastElementIndex = focusableChildrenElements.length - 1
  const isLastElement = e.target === focusableChildrenElements[lastElementIndex]
  const isFirstElement = e.target === focusableChildrenElements[0]
  const isGoingForward = !e.shiftKey

  if (isGoingForward && isLastElement) {
    e.preventDefault()
    focusableChildrenElements[0]?.focus()
  } else if (!isGoingForward && isFirstElement) {
    e.preventDefault()
    focusableChildrenElements[lastElementIndex]?.focus()
  }
}

export const focusTrap: Directive<FocusElement> = {
  mounted(el, binding) {
    if (!binding.value) return
    setTimeout(() => {
      el._focusable = getFocusableChildren(el)
      el._focusableInputs = getFocusableChildren(el, true)
      el._lastFocused = document.activeElement as FocusableElement
    })
    el._keyHandler = (e: KeyboardEvent) => {
      if (e.key === 'Tab' && !isFocusable(e, el._focusable)) {
        el._lastFocused = e.target as FocusableElement
      }
      moveFocus(e, el._focusable)
    }
    document.addEventListener('keydown', el._keyHandler)
  },
  updated(el, binding) {
    if (!binding.value) return
    setTimeout(() => {
      el._focusable = getFocusableChildren(el)
      el._focusableInputs = getFocusableChildren(el, true)
    })
  },
  unmounted(el) {
    if (el._lastFocused) el._lastFocused.focus()
    if (el._keyHandler) {
      document.removeEventListener('keydown', el._keyHandler)
    }
  }
}
